@import '../../variables.scss';

.modal {
  padding: 30px;
  .modal-content {
    border-radius: 25px;
  }
  .screen-size {
    text-align: center;
    h1 {
      color: $tertiary-color;
    }
    p {
      margin-top: 30px;
      padding-bottom: 30px;
    }
  }
}

@media screen and (max-height: 400px) {
  .modal {
    .screen-size {
      img {
        height: 25vh;
      }

      text-align: center;
      p {
        margin-top: 30px;
        padding-bottom: 30px;
      }
    }
  }
}

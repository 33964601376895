@import '../../../variables.scss';

.HomeTemplate, .home-template {
  min-height: calc(100vh - 90px - 60px);
  padding-top: 80px;
  @media (max-width: $screen-sm-max + 1) {
    min-height: calc(100vh - 75px - 80px);
  }
  
  .wrapper {
    margin: 25px;
  }

  .sixteen-nine {
    position: relative;
  }
  .sixteen-nine:before {
    @media screen and (max-width: $screen-md-max - 1) {
      display: block;
      content: '';
      width: 100%;
      padding-top: 56.25%;
    }
  }

  .sixteen-nine > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-color: #ff5729;
    background: transparent;
    margin: auto;
    max-width: $screen-md-max;
    max-height: $screen-md-max * 0.5625;

    @media screen and (min-width: $screen-md-max) {
      width: $screen-md-max - 50;
      height: (($screen-md-max - 50) * 0.5625);
      display: block;
      position: inherit;
    }
  }

  .footer-nav {
    margin: 0 auto;
    height: 40vh;
    width: 100%;
    // background-color: #a2ff29;
    display: flex;
    justify-content: center;
    align-items: center;
    ul {
      margin: 0 auto;
      width: 100%;
      text-align: center;
      list-style: none;
      padding: 0;
      display: flex;
      justify-content: space-evenly;
      li {
        float: left;
        margin-left: 10px;
        a {
         img {
            height: 220px;
        }
    }
      }
    }
    @media screen and (max-width: $screen-sm-max + 1) {
      height: 100px;
    }
  }

  &.homeBackground {
    background-color: $home-background-color;
    background-image: url($home-background-image);
    background-repeat: repeat;
    background-size: 120px;
    
  }

  .hp--flex-wrapper {
    display: flex;
    margin-top: 50px;
  }
}

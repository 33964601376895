@import '../../variables.scss';

.Books-Page {
  iframe {
    padding: 0;
    margin: 0;
    border: 0;
    width: 750px;
    height: 750px;
    margin: 0 auto;
  }
}
@import '../../../variables.scss';

.browse-template {
  .non-bound-container {
    position: relative;
    & > .content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent;
      margin: auto;
      max-width: $screen-md-max;
      @media screen and (min-width: $screen-md-max) {
        width: $screen-md-max - 50;
        height: auto;
        display: block;
        position: inherit;
      }
    }
  }
}

// .play-background {
  // .sixteen-nine {
  //   border: 4px solid yellow;
  //   border-radius: 16px;
  //   overflow: hidden;
  // }
// }
@import '../../../variables';

$selection-window-background-color: #FFFFFF;
$selection-window-border-color: #DB8500;

.SelectionWindowComponent {
  display: inline-block;
  position: relative;
  z-index: 1010;
  position: absolute;
  top: 0;
  background-color: $selection-window-border-color;
  clip-path: circle(76.2% at 24% 22%);
  -webkit-clip-path: circle(76.2% at 24% 22%);
  width: 80px;
  height: 80px;
  border-radius: $content-border-radius 0 0 0;
  
  .stickerSelection {
    width: 60px;
    height: 60px;
    margin: 5px 0 0 2px;
  }
  
  .eraserSelection, .backgroundSelection {
    width: 44px;
    height: 44px;
    margin: 8px 0 0 8px;
    color: $toolbar-icon-color;
  }
  
  .eraserSelection, .backgroundSelection, .stickerSelection, .selectionWindowIcon {
    &:hover {
      cursor: url('/images/Pablo/cursors/ban-solid.svg'), default;
    }
  }
  
  .brushSelectionWindow {
    border-radius: $content-border-radius 0 0 0;
    position: absolute;
    z-index: 1010;
    top: 0px; /* equal to border thickness */
    left: 0px; /* equal to border thickness */
    width: 76px; /* container height - (border thickness * 2) */
    height: 76px; /* container height - (border thickness * 2) */
    clip-path: circle(76.2% at 24% 22%);
    -webkit-clip-path: circle(76.2% at 24% 22%);
    overflow: hidden;
  }
  
  .selectionWindowIcon {
    font-size: 75px;
    position: absolute;
  }
  
  .selectionBrushIcon {
    font-size: 70px;
    top: -14px;
    left: 12px;
  }
  
  .selectionPencilIcon {
    font-size: 70px;
    top: -14px;
    left: 16px;
  }
  
  .selectionSprayIcon {
    font-size: 65px;
    top: 10px;
    left: -4px;
  }
  
  .selectionFillIcon {
    font-size: 65px;
    top: -10px;
    left: -16px;
  }
}

@import '../../variables.scss';
.browse-list-component--wrapper {
  &.play {
    border-color: $tenor-blue;
  }
  &.read {
    border-color: $tenor-purple;
  }
  border-width: 4px;
  border-style: solid;
  border-radius: 16px;
  background: $browse-container-background;
  width: 90%;
  position: absolute;
  right: 0;
  padding: 20px;
  padding-left: 10%;

  @media screen and (max-width: 1220px) {
    padding-left: 0;
  }
}

.browse-list-component {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.play {
  color: $bass-blue;
}
.read {
  color: $bass-purple;
}
.browse-list-component--category-header-title {
  text-align: center;
  display: none;
  font-size: 2em;
  @media screen and (max-width: 1220px) {
    display: block;
  }
}

@import '../../variables.scss';

.browse-list-tile-component {
  margin-bottom: 20px;
  margin: 20px;

  &.read {
    .browse-list-tile-component--card--bottom {
      background: $tenor-purple;
    }
  }
  &.play {
    .browse-list-tile-component--card--bottom {
      background: $tenor-blue;
    }
  }
  .browse-list-tile-component--card {
    margin: 0 auto;
    width: 150px;
    display: flex;
    flex-direction: column;
    height:100%; 
    .browse-list-tile-component--card--image {
      height: 182px;
      color: $button-dark-text;
    }

    .browse-list-tile-component--card--bottom {
      margin-top: 1px;
      text-align: center;
      color: $white;
      width: 150px;
      padding: 10px;
      align-self: flex-end;
      box-sizing: border-box;
      vertical-align: middle;
      height: 100%;
      font-size: 15px;
      line-height: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;

      div {
        width: 100%;
        text-align: center;      
        align-self: center;
      }
      small {
        font-size: 12px;
        line-height: 13px;

      }
    }
  }
  &:hover {
    text-decoration: none;
  }
}
@import './variables.scss';
// Optional
@import '~animate.css/animate.css';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/print';

body, html {
  margin: 0;
  padding: 0;
  font-family: $font-family-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // overflow: hidden;
  height: 100vh;
  & * {
    box-sizing: border-box;
  }
}

a {
  color: white;

  &:hover {
    color: antiquewhite;
  }
}

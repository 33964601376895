@import '../../variables.scss';
.primary-navigation-wrapper {
  padding: 0 25px 0 25px;

  .primary-navigation {
    // transition: all .4s;
    // transform: translate3d(0);
    display: flex;
    max-width: 650px;
    margin: 0 auto;
    text-align: center;
    flex-flow: row;
    justify-content: space-between;
    text-decoration: none;
    height: 150px;

    .primary-navigation--nav-item {
      transition: all 0.5s ease-in-out;
      transform: translate3d(0);

      margin-top: 25px;
      min-height: 44px;
      min-width: 44px;
      max-height: 103px;
      // width: 20%;
      img {
        width: 60%;
        width: 97.5px;
        max-width: 215px;
        max-height: 189px;
      }
      .primary-navigation--nav-item--label {
        width: auto;
        margin: 0 auto;
        background-color: $tertiary-color;
        border-radius: 40px;
        color: $white;
        font-family: 'Fredoka One', cursive;
        text-align: center;
        text-transform: uppercase;
        top: -20px;
        position: relative;
        border: 3px solid brown;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &:hover {
        text-decoration: none;
        transform: scale(1.1);
      }
      &.active {
       transform: scale(1.1);
      }

    }
  }
  &.raised {
    position: relative;
    .primary-navigation--nav-item {
      margin-top: -350px;
      // animation: 1s 0.5s airDrop;
      // animation-fill-mode: forwards;
    }
  }
}

@keyframes airDrop {
  from {
    margin-top: -450px;
  }
  to {
    margin-top: 0px;
  }
}

// NEED TO UPDATE WITH NEW MARKET
.en-US, .en-GB, .en-AU, .en-NZ, .en-IN, .en-AS {
  .primary-navigation-wrapper {
    .primary-navigation {
      max-width: 650px;

      .primary-navigation--nav-item {
        .primary-navigation--nav-item--label {
          font-size: 1.25em;
          height: 35px;
          width: 125px;
          line-height: 1.5em !important;
        }
      }
    }
  }
}

.pt-BR {
  .primary-navigation-wrapper {
    .primary-navigation {
      max-width: 750px;

      .primary-navigation--nav-item {
        .primary-navigation--nav-item--label {
          font-size: 1em;
          line-height: 1em !important;
          height: 40px;
          width: 150px;
        }
      }
    }
  }
}
@import '../../variables.scss';

$biome-spotlight-width: 222px;
$biome-spotlight-height: 140px;
$fill-opacity: 0.35;

.MercatorPage {
  animation: fadeInMap 2s;
  opacity: 0;
  animation-delay: 3s;
  animation-fill-mode: forwards;

  .animate_locales_out {
    animation: fadeOut 1s;
    animation-iteration-count: 1;
    fill-opacity: 1;
    animation-fill-mode: forwards;
  }

  .animate_locales_in {
    animation: fadeIn 1s;
    animation-iteration-count: 1;
    fill-opacity: 1;
    animation-fill-mode: forwards;
  }

  .animate_pins {
    animation: pinOut 0.5s;
    animation-iteration-count: 1;
    fill-opacity: 1;
    animation-fill-mode: forwards;
  }

  .animate_pins_in {
    animation: pinIns 1s;
    animation-iteration-count: 1;
    fill-opacity: 0;
    animation-fill-mode: forwards;
  }

  .biome-navigation {
    position: absolute;
    height: 80%;
    margin-top: 2%;
    .tab-list-flex {
      list-style: none;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      width: 65px;
    }

      // width: auto;
      // height: 46px;
      // text-align: left;
      .button-label {
        margin-left: 7px;
        @media screen and (max-width: 1220px) {
          display: none;
        }
      }
      // }

      button {
        transition: all 0.5s;
        transform: scale(1);
        border-radius: 8px;
        font-size: 18px;
        box-shadow: 0px 3px 0px $bass-green;
        background-color: $white;
        border: 3px solid $bass-green;
        color: $dark-text;
        width: 220px;
        height: 46px!important;
        text-align: left;
        svg,
        .svg-inline--fa {
          width: 1.325em !important;
          height: 100%;
          vertical-align: -0.65em;
        }
        .svg-inline--fa {
          margin-left: 5px!important;
          @media screen and (max-width: 1220px) {
            margin-left: 0!important;
          }
        }
        @media screen and (max-width: 1220px) {
          width: 44px;
          margin: 0 auto;
          text-align: center;
          padding: 0 5px;
        }
        &.desert {
          border-color: $desert-secondary-color;
          box-shadow: 0px 3px 0px $desert-secondary-color;
        }
        &.desert.active,
        &.desert:hover,
        &.desert:active {
          @include collapsableButtons($desert-color, $desert-secondary-color);
        }
        &.grassland {
          border-color: $grassland-secondary-color;
          box-shadow: 0px 3px 0px $grassland-secondary-color;
        }
        &.grassland.active,
        &.grassland:hover,
        &.grassland:active {
          @include collapsableButtons($grassland-color, $grassland-secondary-color);
        }
        &.tropicalForest {
          border-color: $trop-forest-secondary-color;
          box-shadow: 0px 3px 0px $trop-forest-secondary-color;
        }
        &.tropicalForest.active,
        &.tropicalForest:hover,
        &.tropicalForest:active {
          color: $white;
          @include collapsableButtons($trop-forest-color, $trop-forest-secondary-color);
        }
        &.temperateForest {
          border-color: $temp-forest-secondary-color;
          box-shadow: 0px 3px 0px $temp-forest-secondary-color;
        }
        &.temperateForest.active,
        &.temperateForest:hover,
        &.temperateForest:active {
          @include collapsableButtons($temp-forest-color, $temp-forest-secondary-color);
        }
        &.taiga {
          border-color: $taiga-secondary-color;
          box-shadow: 0px 3px 0px $taiga-secondary-color;
        }
        &.taiga.active,
        &.taiga:hover,
        &.taiga:active {
          color: $white;
          @include collapsableButtons($taiga-color, $taiga-secondary-color);
        }
        &.tundra {
          border-color: $tundra-secondary-color;
          box-shadow: 0px 3px 0px $tundra-secondary-color;
        }
        &.tundra.active,
        &.tundra:hover,
        &.tundra:hover {
          color: $white;
          @include collapsableButtons($tundra-color, $tundra-secondary-color);
        }
  
    }
  }
  .biome-spotlight-feature {
    position: absolute;
    right: 12%;
    bottom: 10%;
    padding: 10px;
    width: $biome-spotlight-width;
    height: $biome-spotlight-height;
    background: transparent !important;
    @media only screen and (max-width: 768px) {
      right: 10%;
    }
    &.viewed {
      transform: scale(0.7);
    }
    .biome-spotlight-feature--btn {
      border-radius: 8px;
      padding: 10px 10px 2px 10px;
      width: auto;
      height: auto;
      text-align: center;
      box-shadow: 0px 3px 0px darken($secondary-gray, 30%);
      background-color: $secondary-gray;
      border: 3px solid darken($secondary-gray, 30%);
    }
    &.desert {
      .biome-spotlight-feature--btn {
        @include collapsableButtons($desert-color, $desert-secondary-color);
      }
    }
    &.grassland {
      .biome-spotlight-feature--btn {
        @include collapsableButtons($grassland-color, $grassland-secondary-color);
      }
    }
    &.tropicalForest {
      .biome-spotlight-feature--btn {
        @include collapsableButtons($trop-forest-color, $trop-forest-secondary-color);
      }
    }
    &.temperateForest {
      .biome-spotlight-feature--btn {
        @include collapsableButtons($temp-forest-color, $temp-forest-secondary-color);
      }
    }
    &.taiga {
      .biome-spotlight-feature--btn {
        @include collapsableButtons($taiga-color, $taiga-secondary-color);
      }
    }
    &.tundra {
      .biome-spotlight-feature--btn {
        color: $white;
        @include collapsableButtons($tundra-color, $tundra-secondary-color);
      }
    }
  }
  .biome-spotlight-feature--label {
    text-align: center;
    height: 20px;
    margin-bottom: 10px;
    color: $dark-text;
    @media screen and (min-width: 1220px) {
      visibility: hidden;
    }
  }
  .biome-spotlight-feature--sub-label {
    text-align: center;
    margin-top: 0;
    line-height: 1.25;
  }

  .biome-spotlight-feature--content-box {
    color: $white;
    margin: 0 auto;
    opacity: 1;
    height: 101.25px;
    width: 180px;
  }

  .biome-spotlight-feature--content-box--loading {
    color: $white;
    margin: 0 auto;
    opacity: 1;
  }
  .biome-spotlight-feature--content-box--image-box {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0 auto;
  }
  .biome-spotlight-feature--content-box--image-box--icon {
    svg {
      color: $white;
      font-size: 32px;
      margin: 0 auto;
      text-align: center;
      opacity: 1;
      position: absolute;
      z-index: 2;
      top: 30%;
      left: 45%;
    }
  }
  .biome-spotlight-feature--content-box--image-box--image {
    width: 100%;
    height: 100%;
  }

  .pins,
  .box {
    fill: none;
    fill-opacity: 0;
    stroke: none;
    display: none;
  }

  #svg .box,
  #svg .box .pin {
    display: none;
  }

  svg #map_pin path {
    fill: $pin-color;
    stroke-width: 2px;
    stroke: $white;
  }

  #desert_boxes > *,
  #tundra_boxes > *,
  #taiga_boxes > *,
  #temp_forest_boxes > *,
  #trop_forest_boxes > *,
  #grassland_boxes > * {
    cursor: pointer;
  }

  #neatline {
    stroke: $tenor-green;
    stroke-width: 3.5px;
    fill: none;
  }
  .box.active {
    display: block;
  }

  /*
  SVG Biome Styles
  */

  .desert {
    @include biomeSVGFills($desert-color);
  }
  .grassland {
    @include biomeSVGFills($grassland-color);
  }
  .temp_forest,
  .temperateForest {
    @include biomeSVGFills($temp-forest-color);
  }
  .trop_forest,
  .tropicalForest {
    @include biomeSVGFills($trop-forest-color);
    color: $white;
  }
  .taiga {
    @include biomeSVGFills($taiga-color);
    color: $white;
  }
  .tundra {
    @include biomeSVGFills($tundra-color);
  }
  .water {
    @include biomeSVGFills($water-color);
  }
  .generic {
    @include biomeSVGFills($generic-color);
  }

  /*
   Animation Keyframes 
  */

  @keyframes fadeIn {
    from {
      fill-opacity: $fill-opacity;
    }
    to {
      fill-opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      fill-opacity: 1;
    }
    to {
      fill-opacity: $fill-opacity;
    }
  }

  @keyframes pinIns {
    from {
      fill-opacity: 0;
      display: block;
    }
    to {
      fill-opacity: 1;
    }
  }

  @keyframes pinOut {
    from {
      fill-opacity: 1;
    }
    to {
      fill-opacity: 0;
      display: none;
    }
  }

  @keyframes delayedPulse {
    from {
      transform: scale3d(1, 1, 1);
    }

    50% {
      transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
      transform: scale3d(1, 1, 1);
    }
  }

  .delayed-pulse {
    animation-name: delayedPulese;
    animation-delay: 3s;
  }
}

.MercatorPageSplash {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $explore-splash-background;
  animation: fadeOutIntro 1s ease-out;
  animation-delay: 3s;
  animation-fill-mode: forwards;
  border: 4px solid $tenor-green;
  h1 {
    width: 50%;
    color: $explore-splash-text;
    font-size: 60px;
    line-height: 72px;
  }
  .loading {
    &:after {
      color: $explore-splash-text;
      font-size: 60px;
      line-height: 72px;
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      animation: ellipsis steps(4,end) 900ms infinite;
      content: "\2026"; /* ascii code for the ellipsis character */
      width: 0px;
    }
  }
}

@keyframes fadeOutIntro {
  0% {
    opactiy: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    z-index: -2;
    opacity: 0;
    display: none !important;
  }
}

@keyframes fadeInMap {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    display: flex;
  }
}

@keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}
@mixin background($backgroundImage, $backgroundColor, $isBrowse) {
  background-color: $backgroundColor;
  background-image: url($backgroundImage);
  background-repeat: repeat;
  background-size: 120px;
  @include backgroundDimensions($isBrowse);
}

@mixin collapsableButtons($property, $property2) {
  background-color: $property;
  box-shadow: 0px 3px 0px $property2;
  border: 3px solid;
  border-color: $property2;
  transform: scale(1.1);
  transition: all 0.5s;
}

@mixin biomeSVGFills($property) {
  fill: $property;
  background-color: $property;
}

@mixin backgroundDimensions($isBrowse) {
  @if $isBrowse {
    height: 100%;
  } @else {
    min-height: calc(100vh - 90px);
    @media screen and (max-width: $screen-sm-max + 1) {
      min-height: calc(100vh - 75px);
    }
  }
}
@import '../../variables.scss';

.HomePage {
  height: auto;
  width: 100%;
  background-color: #F0F9DC;

  .title-container {
    width: 80%;
    height: auto;
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .hello-image {
    max-width: 400px;
    position: absolute;
    left: 0;
  }
  
  .message-container {
    padding: 0 0 0 40px;
  }
  
  .title-message {
    margin: 0;
    font-family: $font-family-base;
    padding: 10px 0 10px 0;
    font-size: 34px;
    line-height: $line-height-loose;
    text-align: left;
    color: $britannica-brand-primary;
    @media (max-width: $screen-md-max - 1) {
      font-size: 2.5vw;
      line-height: 3.5vw;
      padding: 9px 0 9px 0;
    }
  }

}

// NEED TO UPDATE WITH NEW MARKET
.en-US, .en-GB, .en-AU, .en-IN, .en-NZ, .en-AS {
  .HomePage {
    .hello-image {
      top: -65px;

      @media (max-width: $screen-md-max - 1) {
        top: -50px;
      }

      @media (max-width: $screen-sm-max - 1) {
        top: -42px;
      }
    }
  }
}

.pt-BR {
  .HomePage {
    .hello-image {
      top: -43px;

       @media (max-width: $screen-md-max - 1) {
         top: -37px;
       }

       @media (max-width: $screen-sm-max - 1) {
         top: -30px;
       }
    }
  }
}

@import '../../variables.scss';

.browse-navigation {
  height: 80%;
  margin-top: 2%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 400px;
  width: 90px;
  left: 0;
  z-index: 2;
}
@import '../../../variables.scss';

.HeaderOnlytTemplate, .headerOnlyBackground {


  &.homeBackground {
    background-color: red;
    background-image: url($home-background-image);
    background-repeat: repeat;
    background-size: 120px;
    
  }

  .hp--flex-wrapper {
    display: flex;
    margin-top: 50px;
  }
}

@import '../../variables.scss';

.BackToHome {
  margin-left: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .home-btn {
    width: 60px;
  }
  
  .branding-btn {
    height: 67px;
    margin-left: 30px;
  }
  
  .branding {
    height: 100%;
  }
}
